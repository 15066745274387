export default [
  {
    path: "Application",
    meta: {
      name: "Application",
      title: "应用中心",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Application/index"),
  },
  {
    path: "/WorkBench/:appId",
    meta: {
      title: "页面管理",
      name: "Application",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Application/WorkBench/index"),
    props: (route) => {
      return {
        appId: Number(route.params.appId),
        pageId: Number(route.params.pageId),
      }
    },
    children: [
      {
        path: "Page/:pageId",
        meta: {
          title: "页面管理",
          name: "Application",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Application/WorkBench/WorkPage"),
        props: (route) => {
          return {
            pageId: Number(route.params.pageId),
          }
        },
      },
    ],
  },
  {
    path: "/Mobile/AccessPage/:appId",
    meta: {
      title: "表单页面",
      name: "MobileAccessPage",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/AccessPage/MobileIndex"),
    props: (route) => {
      return {
        appId: Number(route.params.appId),
      }
    },
  },
  {
    path: "/AccessPage/:appId",
    meta: {
      title: "表单页面",
      name: "AccessPage",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/AccessPage/index"),
    props: (route) => {
      return {
        appId: Number(route.params.appId),
        pageId: Number(route.params.pageId),
      }
    },
    children: [
      {
        path: "Page/:pageId",
        meta: {
          title: " 页面管理",
          name: "AccessPage",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/AccessPage/Page"),
        props: (route) => {
          return {
            pageId: Number(route.params.pageId),
          }
        },
      },
    ],
  },
  {
    path: "/ProcessDetail/:flowInstanceId",
    meta: {
      title: "审核详情",
      name: "ProcessDetail",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/AccessPage/ProcessDetail.vue"),
    props: (route) => {
      return {
        flowInstanceId: Number(route.params.flowInstanceId),
      }
    },
  },
  {
    path: "/PrintTemplate/:templateId",
    meta: {
      title: "打印模板",
      name: "PrintTemplate",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Design/PageSetting/components/PrintTemplate"),
    props: (route) => {
      return {
        templateId: Number(route.params.templateId),
      }
    },
  },
  {
    path: "/PrintPreview/:templateId",
    meta: {
      title: "打印预览",
      name: "PrintPreview",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Design/PageSetting/components/PrintPreview"),
    props: (route) => {
      return {
        templateId: Number(route.params.templateId),
      }
    },
  },
  {
    path: "/ProviderPageSetting/:providerId",
    meta: {
      title: "外部数据集页面设置",
      name: "ProviderPageSetting",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/ProviderSetting"),
    props: (route) => {
      return {
        providerId: Number(route.params.providerId),
      }
    },
  },
]
export const name = "应用"
